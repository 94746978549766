import TopPanel from "../components/TopPanel.tsx";
import Title from "../components/ui/Title.tsx";
import { useSelector } from "react-redux";
import {
  OkxDepositeExpiryTimeSelector,
  changeOkxDepositeExpiryTime,
  changePrimeExpiryTime,
  primeExpiryTimeSelector,
  tasksSelector,
} from "../store/tasks/tasksReducer.ts";
import { GradientCard } from "../components/ui/GradientCard.tsx";
import balanceIcon from "../assets/icons/balance.svg";
import ticketIcon from "../assets/icons/ticket.svg";
import { moneyFormat } from "../helpers/moneyFormat.ts";
import { Task, TaskActionEnum, TaskStatus } from "../store/tasks/api/types.ts";
import chevronRightIcon from "../assets/icons/chevron-right.svg";
import checkmarkCircleIcon from "../assets/icons/checkmark-circle.svg";
import { ChipButton } from "../components/ui/ChipButton.tsx";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { ToastNotification } from "../components/ui/ToastNotification.tsx";
import { useTelegramApp } from "../hooks/useTelegramApp.ts";
import { useAppDispatch } from "../store/hooks.ts";
import { fetchTasksListAction } from "../store/tasks/actions/fetchTasksListAction.ts";
import { claimTaskRewardAction } from "../store/tasks/actions/claimTaskRewardAction.ts";
import { fetchBalanceAction } from "../store/account/actions/fetchBalanceAction.ts";
import { fetchPurchasedCardsListAction } from "../store/cards/actions/fetchPurchasedCardsListAction.ts";
import { startTaskAction } from "../store/tasks/actions/startTaskAction.ts";
import { useToastNotifications } from "../hooks/useToastNotifications.ts";
import { ToastNotificationType } from "../store/toastNotifications/toastNotificationsReducer.ts";
import { useWatch } from "../hooks/usePreviousValue.ts";
import { ExternalImage } from "../components/ui/ExternalImage.tsx";
import { DataLayerEventNames, useDataLayer } from "../hooks/useDataLayer.ts";
import { ClickerGameTimerRef } from "../components/Clicker/GameTimer.tsx";
import { Timer } from "../components/ui/Timer.tsx";
import { userIdSelector } from "../store/account/account.tsx";
import AdsTasks from "../components/Tasks/AdsTasks.tsx";
import { getRewardOKXDeposite } from "../api/user/getRewardOKXDeposite.ts";
// import TapAddsTasks from "../components/Tasks/TapAddsTasks.tsx";
import { PopupTapAddsList } from "../components/Tasks/TapAds/PopupTapAddsList.tsx";
import ContentChallengeTask from "../components/Tasks/ContentChallengeTask.tsx";
// import { TadsWidget } from "react-tads-widget";

export function TasksPage() {
  const tasks = useSelector(tasksSelector);
  const userId = useSelector(userIdSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { trackEvent } = useDataLayer();

  const { showNotification } = useToastNotifications();

  const tgApp = useTelegramApp();
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [isNotificationShown, setIsNotificationShown] = useState(false);
  const [notificationText, setNotificationText] = useState<string | null>(null);

  const [isClaimPending, setIsClaimPending] = useState(() => false);
  const [isStartPending, setIsStartPending] = useState(() => false);

  const [taskList, setTaskList] = useState<Task[]>([]);
  const [partnerTasks, setPartnerTasks] = useState<Task[]>([]);

  // useEffect(() => {
  //   let parnersKeys = ["join_primeXBT", "deposit_primeXBT", "join_OKX"];

  //   setPartnerTasks(tasks.filter((task) => parnersKeys.includes(task.key)));
  //   setTaskList(tasks.filter((task) => !parnersKeys.includes(task.key)));
  // }, [tasks]);

  useEffect(() => {
    dispatch(fetchTasksListAction());
    dispatch(changePrimeExpiryTime(getExpiryTimeFromLocalStorage("redirect to exchange")));
    dispatch(changeOkxDepositeExpiryTime(getExpiryTimeFromLocalStorage(TaskActionEnum.REDIRECT_TO_OKX_DEPOSITE)));
  }, []);

  const togglePopup = () => {
    setIsPopupOpen((prev) => !prev);
  };

  useWatch(tasks, (tasks) => {
    const twitterTask = tasks.find(({ action_name }) => action_name === TaskActionEnum.REDIRECT_TO_TWITTER);

    if (twitterTask?.status === TaskStatus.AWAITING && !localStorage.getItem("twitter_verify_message_seen")) {
      showNotification({
        title: "We check your Twitter account during 24h to verify the task. Please wait.",
        type: ToastNotificationType.Info,
      });

      localStorage.setItem("twitter_verify_message_seen", "true");
    }

    const primeTask = tasks.find(({ action_name }) => action_name === TaskActionEnum.REDIRECT_TO_EXCHANGE);
    if (primeTask)
      if (primeTask.status === TaskStatus.AWAITING && getExpiryTimeFromLocalStorage(primeTask.action_name) == null) {
        saveExpiryTimeToLocalStorage(TaskActionEnum.REDIRECT_TO_EXCHANGE);
      }
    const allParnersKeys = [
      "join_primeXBT",
      "deposit_primeXBT",
      "join_OKX",
      "deposit_OKX",
      "parthner_spicial",
      "join_token_airdrop",
    ];
    const parnersKeysWithoutOKX = ["join_primeXBT", "deposit_primeXBT", "parthner_spicial"];

    setPartnerTasks(tasks.filter((task) => parnersKeysWithoutOKX.includes(task.key)));
    setTaskList(tasks.filter((task) => !allParnersKeys.includes(task.key)));
  });

  async function handleTaskClick(id: Task["id"]) {
    const task = tasks.find((task) => task.id === id);

    if (!task) return;

    if (task.status === TaskStatus.TO_DO && task.action_name != TaskActionEnum.REDIRECT_TO_OKX) {
      if (task.action_name === TaskActionEnum.REDIRECT_TO_OKX_DEPOSITE) {
        const result = await getRewardOKXDeposite();
        if (result.message) {
          showNotification({
            title: result.message,
            type: ToastNotificationType.Error,
            timeoutMs: 5000,
          });
        }
      }
      clearExpiredKeyFromLocalStorage(task.action_name);
      if (isStartPending) return; // Lock button on double click

      setIsStartPending(true);
      const result = await dispatch(startTaskAction(task.id));

      if (!startTaskAction.fulfilled.match(result)) {
        setIsStartPending(false);

        return showNotification({
          title: "Something went wrong. Please try again later.",
          type: ToastNotificationType.Error,
          timeoutMs: 5000,
        });
      }

      dispatch(fetchTasksListAction()).finally(() => {
        setIsStartPending(false);
      });
    }

    if (task.status !== TaskStatus.CLAIM_REWARD) {
      switch (task.action_name) {
        case TaskActionEnum.REDIRECT_TO_CHAT:
          tgApp.openTelegramLink(task.action_url || "");
          return;

        case TaskActionEnum.REDIRECT_TO_GROUP:
          tgApp.openTelegramLink(task.action_url || "");
          return;

        case TaskActionEnum.REDIRECT_TO_TWITTER:
          tgApp.openLink(task.action_url || "");
          return;

        case TaskActionEnum.REDIRECT_TO_DISCORD:
          tgApp.openLink(task.action_url || "");
          return;

        case TaskActionEnum.INVITE_FRIEND:
          navigate("/friends");
          return;

        case TaskActionEnum.REDIRECT_TO_YOUTUBE:
          tgApp.openLink(task.action_url || "");
          return;

        case TaskActionEnum.REDIRECT_TO_OKX:
          if (task.status !== TaskStatus.COMPLETE) navigate("/exchange-registration");
          return;

        case TaskActionEnum.REDIRECT_TO_EXCHANGE:
          if (getExpiryTimeFromLocalStorage(task.action_name) !== null || task.status === TaskStatus.TO_DO) {
            if (task.key === "deposit_OKX") {
              tgApp.openLink(task.action_url || "");
            } else tgApp.openLink(`${task.action_url}=${userId}`);

            saveExpiryTimeToLocalStorage(TaskActionEnum.REDIRECT_TO_EXCHANGE);
            dispatch(changePrimeExpiryTime(getExpiryTimeFromLocalStorage(TaskActionEnum.REDIRECT_TO_EXCHANGE)));
          }

          if (getExpiryTimeFromLocalStorage(task.action_name) == null && task.status == TaskStatus.AWAITING) {
            saveExpiryTimeToLocalStorage(TaskActionEnum.REDIRECT_TO_EXCHANGE);
            dispatch(changePrimeExpiryTime(getExpiryTimeFromLocalStorage(TaskActionEnum.REDIRECT_TO_EXCHANGE)));
            dispatch(fetchTasksListAction());
          }
          return;

        case TaskActionEnum.REDIRECT_TO_OKX_DEPOSITE:
          if (getExpiryTimeFromLocalStorage(task.action_name) !== null || task.status === TaskStatus.TO_DO) {
            tgApp.openLink(task.action_url || "");

            saveExpiryTimeToLocalStorage(TaskActionEnum.REDIRECT_TO_OKX_DEPOSITE);
            dispatch(
              changeOkxDepositeExpiryTime(getExpiryTimeFromLocalStorage(TaskActionEnum.REDIRECT_TO_OKX_DEPOSITE)),
            );
          }

          if (getExpiryTimeFromLocalStorage(task.action_name) == null && task.status == TaskStatus.AWAITING) {
            const result = await getRewardOKXDeposite();
            if (result.message) {
              showNotification({
                title: result.message,
                type: ToastNotificationType.Error,
                timeoutMs: 5000,
              });
            }
            dispatch(fetchTasksListAction());
            saveExpiryTimeToLocalStorage(TaskActionEnum.REDIRECT_TO_OKX_DEPOSITE);
            dispatch(
              changeOkxDepositeExpiryTime(getExpiryTimeFromLocalStorage(TaskActionEnum.REDIRECT_TO_OKX_DEPOSITE)),
            );
          }
          return;

        default:
          return;
      }
    }

    if (task.status === TaskStatus.CLAIM_REWARD) {
      if (isClaimPending) return; // Lock button on double click

      setIsClaimPending(true);
      const claimResult = await dispatch(claimTaskRewardAction(task.id));

      if (claimTaskRewardAction.fulfilled.match(claimResult)) {
        trackEvent(DataLayerEventNames.questCompleted, {
          quest_name: task.title,
        });

        dispatch(fetchTasksListAction()).then(() => {
          setIsClaimPending(false);
        });

        dispatch(fetchBalanceAction());
        dispatch(fetchPurchasedCardsListAction());

        return showNotification({
          title: task.title,
          type: ToastNotificationType.Success,
          timeoutMs: 2000,
        });
      }

      setIsClaimPending(false);

      showNotification({
        title: "Something went wrong. Please try again later.",
        type: ToastNotificationType.Error,
      });
    }

    if (task.status === TaskStatus.CLAIM_REWARD) {
      if (isClaimPending) return; // Lock button on double click

      setIsClaimPending(true);
      const claimResult = await dispatch(claimTaskRewardAction(task.id));

      if (claimTaskRewardAction.fulfilled.match(claimResult)) {
        trackEvent(DataLayerEventNames.questCompleted, {
          quest_name: task.title,
        });

        dispatch(fetchTasksListAction()).then(() => {
          setIsClaimPending(false);
        });

        dispatch(fetchBalanceAction());
        dispatch(fetchPurchasedCardsListAction());

        return showNotification({
          title: task.title,
          type: ToastNotificationType.Success,
        });
      }

      setIsClaimPending(false);

      showNotification({
        title: "Something went wrong. Please try again later.",
        type: ToastNotificationType.Error,
      });
    }
  }

  return (
    <>
      <div className="pb-[100px]">
        <TopPanel page="Tasks" />

        <div className="mt-[25px]">
          <Title text="Earn more coins" />
        </div>
        <AdsTasks />
        {/* <TapAddsTasks onOpenPopup={togglePopup} /> */}
        <div className="pb-[14px]">
          <h2 className="text-[16px] font-semibold opacity-75 text-center">Partners Specials</h2>
          <ul className="space-y-2 mt-[14px]">
            {partnerTasks.map((task) => (
              <li key={task.id}>
                <GradientCard backgroundColor="#28202C">
                  <button
                    type="button"
                    className="flex-1 p-1 w-full flex gap-x-3 items-center pr-3"
                    onClick={() => handleTaskClick(task.id)}
                  >
                    <span className="flex grow gap-x-3 items-center">
                      <span className="h-[56px] w-[56px] relative bg-white/25 rounded-xl overflow-hidden flex justify-center items-center">
                        <ExternalImage
                          loading="lazy"
                          className="size-[28px] object-cover object-center"
                          src={task.icon}
                          alt="Task icon"
                        />
                      </span>

                      <span className="flex-1">
                        <span className="text-left text-[16px] font-medium block break-all capitalize">
                          {task.title}
                        </span>
                        <strong className="flex mt-0.5 text-orangeLight items-center gap-x-[0.286em] text-[14px] pr-4">
                          {task.capacity > 0 && (
                            <>
                              <span className="size-4">
                                <img
                                  className="size-full object-cover"
                                  loading="lazy"
                                  src={balanceIcon}
                                  alt="currency"
                                />
                              </span>
                              {moneyFormat(task.capacity)}
                            </>
                          )}
                          {task.ticketCapacity > 0 && (
                            <>
                              <span className="size-4">
                                <img className="size-full object-cover" loading="lazy" src={ticketIcon} alt="ticket" />
                              </span>
                              {moneyFormat(task.ticketCapacity)}
                            </>
                          )}
                        </strong>
                      </span>
                    </span>

                    <TaskAction task={task} />
                  </button>
                </GradientCard>
              </li>
            ))}
          </ul>
        </div>
        <div>
          <h2 className="text-[16px] font-semibold opacity-75 text-center">Tasks list</h2>
          <ul className="space-y-2 mt-[14px]">
            <ContentChallengeTask />
            {taskList.map((task) => (
              <li key={task.id}>
                <GradientCard backgroundColor="#28202C">
                  <button
                    type="button"
                    className="flex-1 p-1 w-full flex gap-x-3 items-center pr-3"
                    onClick={() => handleTaskClick(task.id)}
                  >
                    <span className="flex grow gap-x-3 items-center">
                      <span className="h-[56px] w-[56px] relative bg-white/25 rounded-xl overflow-hidden flex justify-center items-center">
                        <ExternalImage
                          loading="lazy"
                          className="size-[28px] object-cover object-center"
                          src={task.icon}
                          alt="Task icon"
                        />
                      </span>

                      <span className="flex-1">
                        <span className="text-left text-[16px] font-medium block break-all capitalize">
                          {task.title}
                        </span>

                        <strong className="flex mt-0.5 text-orangeLight items-center gap-x-[0.286em] text-[14px] pr-4">
                          {task.capacity > 0 && (
                            <>
                              <span className="size-4">
                                <img
                                  className="size-full object-cover"
                                  loading="lazy"
                                  src={balanceIcon}
                                  alt="currency"
                                />
                              </span>
                              {moneyFormat(task.capacity)}
                            </>
                          )}
                          {task.ticketCapacity > 0 && (
                            <>
                              <span className="size-4">
                                <img className="size-full object-cover" loading="lazy" src={ticketIcon} alt="ticket" />
                              </span>
                              {moneyFormat(task.ticketCapacity)}
                            </>
                          )}
                        </strong>
                      </span>
                    </span>

                    <TaskAction task={task} />
                  </button>
                </GradientCard>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <ToastNotification
        isOpened={isNotificationShown}
        onClose={() => {
          setIsNotificationShown(false);
          setNotificationText(null);
        }}
      >
        {notificationText}
      </ToastNotification>
      <PopupTapAddsList isOpened={isPopupOpen} onClose={togglePopup} />
    </>
  );
}

function TaskAction({ task: { status, action_name } }: { task: Task }) {
  const dispatch = useAppDispatch();
  const primeExpiryTime = useSelector(primeExpiryTimeSelector);
  const okxDepositeExpiryTime = useSelector(OkxDepositeExpiryTimeSelector);

  const taskTimerRef = useRef<ClickerGameTimerRef>(null);
  const taskOkxDepositeTimerRef = useRef<ClickerGameTimerRef>(null);

  switch (status) {
    case TaskStatus.TO_DO:
      return (
        <span className="block mr-2">
          <img src={chevronRightIcon} alt="Right arrow" />
        </span>
      );

    case TaskStatus.AWAITING: {
      if (action_name === TaskActionEnum.REDIRECT_TO_EXCHANGE) {
        if (primeExpiryTime !== null) {
          taskTimerRef.current?.start();

          return (
            <Timer
              ref={taskTimerRef}
              format="mm:ss"
              remainingTime={primeExpiryTime as number}
              onEnd={() => {
                dispatch(changePrimeExpiryTime(null));
                dispatch(fetchTasksListAction());
              }}
            />
          );
        } else {
          return <ChipButton as="span" text="Claim" />;
        }
      } else if (action_name === TaskActionEnum.REDIRECT_TO_OKX_DEPOSITE) {
        if (okxDepositeExpiryTime !== null) {
          taskOkxDepositeTimerRef.current?.start();

          return (
            <Timer
              ref={taskOkxDepositeTimerRef}
              format="mm:ss"
              remainingTime={okxDepositeExpiryTime as number}
              onEnd={() => {
                dispatch(changeOkxDepositeExpiryTime(null));
                dispatch(fetchTasksListAction());
              }}
            />
          );
        } else {
          return <ChipButton as="span" text="Claim" />;
        }
      } else {
        return <ChipButton as="span" text="Claim" disabled={true} />;
      }
    }

    case TaskStatus.CLAIM_REWARD:
      return <ChipButton as="span" text="Claim" />;

    case TaskStatus.COMPLETE:
      return (
        <span className="block mr-2 size-5">
          <img className="size-full object-cover" src={checkmarkCircleIcon} alt="Checkmark" />
        </span>
      );
    default:
      return null;
  }
}

export const saveExpiryTimeToLocalStorage = (key: string, ms: number = 30 * 60 * 1000) => {
  const now = new Date();

  const expiryTime = new Date(now.getTime() + ms);

  const expiryTimeString = expiryTime.toISOString();

  localStorage.setItem(key, expiryTimeString);
};

export const getExpiryTimeFromLocalStorage = (key: string) => {
  const expiryTimeString = localStorage.getItem(key);
  if (expiryTimeString) {
    const expiryTime = new Date(expiryTimeString).getTime();
    const currentTime = Date.now();

    if (expiryTime > currentTime) {
      const timeDifference = expiryTime - currentTime;
      return timeDifference;
    } else {
      clearExpiredKeyFromLocalStorage(key);
      return null;
    }
  } else {
    return null;
  }
};

export const clearExpiredKeyFromLocalStorage = (key: string) => {
  const expiryTimeString = localStorage.getItem(key);
  if (expiryTimeString) {
    localStorage.removeItem(key);
  }
};

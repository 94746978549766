import { useTelegramApp } from "../../../hooks/useTelegramApp";
import { BottomSheet } from "../../ui/BottomSheet";
import Button from "../../ui/Button";
import nftPopUpImage from "../../../assets/img/limited-nft-presale.png";

interface LimitedNftPopUpProps {
  isOpened: boolean;
  onClose: () => void;
}

const LimitNftPopUp = ({ isOpened, onClose }: LimitedNftPopUpProps) => {
  const tgApp = useTelegramApp();

  const handleOpenSite = () => {
    tgApp.openLink("https://presale.magiceden.io/pay/66c70691991efe89d4f0fc83");
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <BottomSheet isOpened={isOpened} onClose={handleClose} canBeClosedOnTouchMove={true} canBeClosedOnClickInside={true}>
      <div className="relative w-full flex justify-center">
        <img 
          src={nftPopUpImage} 
          alt="nftPopUpImage" 
          className="w-[60vw] absolute"
          style={{ bottom: "-80px" }}
        />
      </div>
      <div className="mt-[78px] text-center">
      <h1 style={{
          fontFamily: "var(--font-family)",
          fontWeight: 900,
          fontSize: "24px",
          textAlign: "center",
          color: "var(--hamstercard.pro-white-mine-shaft)",
          marginBottom: 10
      }}>
          LIMITED PRESALE NOW 🤩
      </h1>
        <p
          style={{
            fontFamily: "var(--font-family)",
            fontWeight: 500,
            fontSize: "18px",
            textAlign: "center",
            color: "var(--hamstercard.pro-white-mine-shaft)",
            lineHeight: "1.2",
          }}
        >
          Check out your <span style={{ fontWeight: 900, color: "#ffee05" }}>50% discount</span> for <br />
          <span style={{ fontWeight: 700 }}>OrbitonX Rockets</span> NFT Collection! <br />
          Don't miss it!
        </p>
      </div>

      <Button
        text="GO TO MAGIC EDEN"
        className="mt-[17px]"
        handleClick={handleOpenSite}
        style="linear-gradient(162deg, #fe3f8f 0%, #e42575 100%)"
      />
    </BottomSheet>
  );
};

export default LimitNftPopUp;